import { CommonModule, CurrencyPipe, DecimalPipe, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';

import {
  CountPipe, DecimalDirective, DefaultIfSetPipe,
  DefaultPipe, FilterPipe, FormatPipe,
  JoinPipe, MapPipe, SafePipe, SumPipe, UppercaseDirective, UtcDatePipe,
} from '@depot/@common';

@NgModule({
  imports: [
    CommonModule,
    NgOptimizedImage,
  ],
  declarations: [
    UppercaseDirective,
    DecimalDirective,
    UtcDatePipe,
    DefaultPipe,
    SafePipe,
    SumPipe,
    DefaultIfSetPipe,
    FormatPipe,
    JoinPipe,
    CountPipe,
    FilterPipe,
    MapPipe,

  ],
  exports: [
    UppercaseDirective,
    DecimalDirective,
    UtcDatePipe,
    DefaultIfSetPipe,

    DefaultPipe,
    SafePipe,
    SumPipe,
    JoinPipe,
    CountPipe,
    FilterPipe,
    FormatPipe,
    MapPipe,

  ],
  providers: [
    DecimalPipe,
    CurrencyPipe,
  ]
})
export class DepotCommonModule { }

export enum CrossRefDescription {

  R = 'Replaced By: ',
  O = 'Old Number: ',
  S = 'Same As: ',
  W = 'Will Fit: ',
  B = 'Asy/Kit includes component: ',
  M = 'Component of: ',
}

export enum FilterType {
  StartsWith = '0',
  Contains = '1',
  EndsWith = '2',
  Exact = '3',
}

export enum MessageType {
  Info = 1,
  Error = 2,
  Warning = 3,
  Success = 4
}

export enum NotificationMessageFormat {
  String = 'String',
  O365Email = 'O365Email',
  DealerReturn = 'DealerReturn',
}

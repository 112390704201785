import {
  Directive,
  ElementRef,
  HostListener,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[depotUppercase]'
})
export class UppercaseDirective {

  private lastValue: string = null;

  constructor(
    public ref: ElementRef,
    private renderer: Renderer2,
  ) { }
  // HTMLInputElement
  @HostListener('input', ['$event']) onInput($event: any) {
    $event.preventDefault();
    $event.target.value = ($event.target.value || '').toUpperCase();

    if (this.lastValue === null || (this.lastValue && $event.target.value.length > 0 && this.lastValue !== $event.target.value)) {
      this.lastValue = $event.target.value;
      this.renderer.setValue(this.ref.nativeElement, this.lastValue);
      // this.ref.nativeElement.value = $event.target.value;
      // Propagation
      const evt = new Event("input", {
        bubbles: false,
        cancelable: true
      })
      $event.target.dispatchEvent(evt);
    }
  }
}

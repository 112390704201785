import { DragDropModule } from '@angular/cdk/drag-drop';
import { LayoutModule } from '@angular/cdk/layout';
import { CdkScrollableModule, ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  DateFnsAdapter,
  MAT_DATE_FNS_FORMATS,
  MatDateFnsModule,
  provideDateFnsAdapter,
} from '@angular/material-date-fns-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatRippleModule
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import {
  CameraComponent,
  CameraDialogComponent,
  ConfirmationDialogComponent,
  DefaultChildComponent,
  FormErrorComponent,
  IFrameDialogComponent,
  ImageEditDialogComponent,
  ImageRowComponent,
  ImageRowFormComponent,
  MessageDialogComponent,
  PromptDialogComponent,
} from '@depot/@components';
import { PaginatorIntlCustom } from '@depot/@extension';

import { AngularEditorModule } from '@kolkov/angular-editor';
import { enUS } from 'date-fns/locale';
import { LightboxModule } from 'ngx-lightbox';

@NgModule({
  imports: [
    AngularEditorModule,
    CommonModule,
    DragDropModule,
    FormsModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatDatepickerModule,
    MatDateFnsModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatInputModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    ReactiveFormsModule,
    ScrollingModule,
    LayoutModule,
    LightboxModule,
    RouterModule,
  ],
  declarations: [
    CameraComponent,
    ConfirmationDialogComponent,
    PromptDialogComponent,
    MessageDialogComponent,
    CameraDialogComponent,
    FormErrorComponent,
    IFrameDialogComponent,
    ImageEditDialogComponent,
    ImageRowComponent,
    ImageRowFormComponent,
    DefaultChildComponent,
  ],
  providers: [
    { provide: MatPaginatorIntl, useClass: PaginatorIntlCustom },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: {
        showDelay: 1000,
        hideDelay: 0,
        touchendHideDelay: 0,
      }
    },
    { provide: MAT_DATE_LOCALE, useValue: enUS },
    { provide: DateAdapter, useClass: DateFnsAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_FORMATS },
    provideDateFnsAdapter()

  ],
  exports: [
    AngularEditorModule,
    CameraComponent,
    CdkScrollableModule,
    DefaultChildComponent,
    FormErrorComponent,
    FormsModule,
    IFrameDialogComponent,
    ImageEditDialogComponent,
    DragDropModule,
    ImageRowComponent,
    ImageRowFormComponent,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatInputModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    ReactiveFormsModule,
    CdkScrollableModule,
    ScrollingModule,
  ]
})
export class DepotComponentsModule { }

